import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'firebase/storage';
import HomePage from './HomePage';
import DemoPage from './DemoPage';
import AboutPage from './AboutPage';
import TeamPage from './TeamPage';
import Header from './Header';
import ContactPage from './ContactPage';
import WearablePage from './WearablePage';
//import RegisterPage from './RegisterPage'
import { getStorage } from "firebase/storage"
import DatasetsPage from './DatasetsPage';
import BlogPage from './About/BlogPage';
import BlogPostPage from './About/BlogPostPage';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Footer from './Footer/Footer';
import Dashboard from './UserPanel/Dashboard';
import HeartRateMonitor from './Live/OpenHRVLive';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzDBVjT7Rhv4eS91Dci4ngSQrN2SHgVlg",
  authDomain: "openhrv.firebaseapp.com",
  projectId: "openhrv",
  storageBucket: "openhrv.appspot.com",
  messagingSenderId: "660633507663",
  appId: "1:660633507663:web:ddb8070ea823f635c6173a",
  measurementId: "G-DTM32SJLEC",
  databaseURL: "https://openhrv-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

root.render(
  <BrowserRouter>
   <Header></Header>
    <Routes>
      <Route path="/" element= {<HomePage />} />
      <Route path="/hrv-calculation-demo" element={<DemoPage />} />
      <Route path="/about-hrv" element={<AboutPage/>} />
      <Route path="/contact" element={<ContactPage/>} />
      <Route path="/open-hrv-team" element={<TeamPage/>} />
      <Route path="/hrv-datasets" element={<DatasetsPage/>} />
      <Route path="/wearables-we-support" element={<WearablePage/>} />
      <Route path="/blog" element={<BlogPage/>} />
      <Route path="/blog/:postId" element={<BlogPostPage/>} />
      <Route path='/dashboard' element={<Dashboard/>} />
      <Route path='/live' element={<HeartRateMonitor/>} />
    </Routes>
    <Footer></Footer>
  </BrowserRouter>
);
logEvent(analytics, 'page rendered');
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Named export
export { analytics, storage };