import React, { useState, useEffect } from 'react';

const SERVICE_UUID = 'heart_rate'; // Use standard service name
const CHARACTERISTIC_UUID = 'heart_rate_measurement'; // Use standard characteristic name

const HeartRateMonitor = () => {
  const [device, setDevice] = useState(null);
  const [server, setServer] = useState(null);
  const [hrCharacteristic, setHrCharacteristic] = useState(null);
  const [rrIntervals, setRrIntervals] = useState([]);
  const [metrics, setMetrics] = useState({
    rmssd: null,
    sdnn: null,
    stressIndex: null,
    heartRate: null,
    readiness: null,
  });

  const connectToDevice = async () => {
    try {
      const options = {
        filters: [{ namePrefix: 'H10' }],
      };

      const device = await navigator.bluetooth.requestDevice({
           acceptAllDevices: true
         });
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(SERVICE_UUID);
      const characteristic = await service.getCharacteristic(CHARACTERISTIC_UUID);

      setDevice(device);
      setServer(server);
      setHrCharacteristic(characteristic);
    } catch (error) {
      console.error('Failed to connect: ' + error);
    }
  };

  useEffect(() => {
    if (hrCharacteristic) {
      hrCharacteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
      hrCharacteristic.startNotifications();

      return () => {
        hrCharacteristic.removeEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
        hrCharacteristic.stopNotifications();
      };
    }
  }, [hrCharacteristic]);

  const handleCharacteristicValueChanged = (event) => {
    const value = event.target.value;
    processHRVData(value);
  };

  const processHRVData = (value) => {
    const flags = value.getUint8(0);
    let index = 1;
    let heartRate;

    if (flags & 0x01) {
      heartRate = value.getUint16(index, true);
      index += 2;
    } else {
      heartRate = value.getUint8(index);
      index += 1;
    }

    if (flags & 0x10) {
      const rrIntervalsTemp = [];
      while (index + 1 < value.byteLength) {
        let rrInterval = value.getUint16(index, true) / 1024;
        index += 2;
        if (rrInterval >= 0.3 && rrInterval <= 2.0) {
          rrIntervalsTemp.push(rrInterval);
        }
      }
      setRrIntervals((prevIntervals) => [...prevIntervals, ...rrIntervalsTemp]);
    }
  };

  useEffect(() => {
    if (rrIntervals.length > 0) {
      calculateMetrics();
    }
  }, [rrIntervals]);

  const calculateRMSSD = (rrIntervals) => {
    if (rrIntervals.length < 2) return null;
    let sumSquares = 0;
    for (let i = 1; i < rrIntervals.length; i++) {
      let diff = rrIntervals[i] - rrIntervals[i - 1];
      sumSquares += diff * diff;
    }
    const rmssd = Math.sqrt(sumSquares / (rrIntervals.length - 1));
    return rmssd;
  };

  const calculateSDNN = (rrIntervals) => {
    if (rrIntervals.length === 0) return null;
    const mean = rrIntervals.reduce((a, b) => a + b) / rrIntervals.length;
    const sumSquares = rrIntervals.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0);
    const sdnn = Math.sqrt(sumSquares / rrIntervals.length);
    return sdnn;
  };

  const calculateStressIndex = (rrIntervals) => {
    if (rrIntervals.length === 0) return null;
    const intervalsMs = rrIntervals.map((interval) => interval * 1000);
    const hist = {};

    intervalsMs.forEach((interval) => {
      const rounded = Math.round(interval / 50) * 50;
      hist[rounded] = (hist[rounded] || 0) + 1;
    });

    const mode = parseFloat(
      Object.keys(hist).reduce((a, b) => (hist[a] > hist[b] ? a : b))
    );
    const amo = (hist[mode] / intervalsMs.length) * 100;
    const varRR = Math.max(...intervalsMs) - Math.min(...intervalsMs);

    const stressIndex = (amo * 100) / (2 * mode * (varRR / 1000));

    return stressIndex;
  };

  const calculateHeartRate = (rrIntervals) => {
    if (rrIntervals.length === 0) return null;
    const avgRR = rrIntervals.reduce((a, b) => a + b) / rrIntervals.length;
    return 60 / avgRR;
  };

  const calculateReadiness = (stressIndex, heartRate) => {
    if (stressIndex == null || heartRate == null) return null;
    const readiness = 100 - stressIndex - heartRate / 2;
    return readiness;
  };

  const calculateMetrics = () => {
    const rmssd = calculateRMSSD(rrIntervals);
    const sdnn = calculateSDNN(rrIntervals);
    const stressIndex = calculateStressIndex(rrIntervals);
    const heartRate = calculateHeartRate(rrIntervals);
    const readiness = calculateReadiness(stressIndex, heartRate);

    setMetrics({
      rmssd,
      sdnn,
      stressIndex,
      heartRate,
      readiness,
    });
  };

  return (
    <div>
      <h1>Heart Rate Monitor</h1>
      <button onClick={connectToDevice}>Connect to Device</button>
      <div>
        <h2>Metrics:</h2>
        <p>RMSSD: {metrics.rmssd ? metrics.rmssd.toFixed(2) : 'N/A'}</p>
        <p>SDNN: {metrics.sdnn ? metrics.sdnn.toFixed(2) : 'N/A'}</p>
        <p>Stress Index: {metrics.stressIndex ? metrics.stressIndex.toFixed(2) : 'N/A'}</p>
        <p>Heart Rate: {metrics.heartRate ? metrics.heartRate.toFixed(2) : 'N/A'}</p>
        <p>Readiness: {metrics.readiness ? metrics.readiness.toFixed(2) : 'N/A'}</p>
      </div>
    </div>
  );
};

export default HeartRateMonitor;
