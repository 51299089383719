import React, { useState } from 'react';
import './App.css';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';

const HRVDataset = ({ dataset }) => {
    const [showInterpretation, setShowInterpretation] = useState(false);
    const [showComments, setShowComments] = useState(false);
    
    const handleDownload = () => {
      logEvent(analytics, 'Dataset download clicked', { dataset_title: dataset.title });
      window.open(dataset.download_url, '_blank');
    };

    const handleDownloadInterpretation = () => {
      logEvent(analytics, 'Dataset interpretation clicked', { dataset_title: dataset.title });
      window.open(dataset.interpretation, '_blank');
    }

    return (
      <div className="dataset-tile" style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
        <h2>{dataset.title}</h2>
        <p>{dataset.description}</p>
        
        <div>
          <strong>Tags:</strong> {dataset.tags.map(tag => <span key={tag}>{tag}</span>)}
        </div>
        
        <div style={{ marginTop: '10px' }}>
          <button onClick={handleDownload}>Download</button>
        </div>

        <div style={{ marginTop: '10px' }}>
          <button onClick={handleDownloadInterpretation}>Interpretation</button>
        </div>
      </div>
    );
  };
  
  const HRVDatasetList = ({ datasets }) => {
    return (
      <div>
        {datasets.map((dataset, index) => (
          <HRVDataset key={index} dataset={dataset} />
        ))}
      </div>
    );
  };

  const datasets = [
    {
      title: 'ECG with ketamine',
      description: 'This dataset contains ECG data from a patient who was administered ketamine.',
      tags: ['HRV', 'Health', 'Ketamine', 'Depression'],
      interpretation: 'https://drive.google.com/file/d/1Cjvz6yEbjUKuf1iUAhYXoSL3lomkQTA5/view?usp=drive_link',
      comments: ['Comment 1', 'Comment 2'],
      download_url: "https://drive.google.com/file/d/1U7KmlU1scwdWtNWimojCUbBBZ1ixmNrV/view?usp=drive_link"
    },
    {
      title: 'Polar single record',
      description: 'This dataset contains HRV data from a single patient who was monitored using a Polar H10 device.',
      tags: ['HRV', 'Health', 'Exercise', 'Polar'],
      interpretation: 'https://drive.google.com/file/d/14GA_5oC4cGuZWCiSafyGWWsnnuxA3Mbu/view?usp=drive_link',
      comments: ['Comment 1'],
      download_url: "https://drive.google.com/file/d/1ZqnnE7t2RMB-CeLiR1WqtFP_sl7tpX_S/view?usp=sharing",
    },
    {
      title: 'Daily Activity with HRV',
      description: 'This dataset contains HRV data from a patient who was monitored for daily activity.',
      tags: ['HRV', 'Exercise', 'Activity'],
      interpretation: 'https://drive.google.com/file/d/1zRokd1MfbPzJ5Je6tiS2vVTN_MQT0t56/view?usp=drive_link',
      comments: ['Comment 1'],
      download_url: "https://drive.google.com/file/d/1j2SLlwgxKQ8dRittGCLW9POE-4JLCkDm/view?usp=drive_link"
    }
  ];
function DatasetsPage() {
  logEvent(analytics, 'Dataset visited');
  return (
    <div  className='container'>
      <h1>HRV Datasets</h1>
      <p>Explore various HRV datasets. Learn our documentation. Use Open HRV API for analytics</p>
      <HRVDatasetList datasets={datasets} />
    </div>
  );
};

export default DatasetsPage;
