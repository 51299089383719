import React, { useState, useEffect } from 'react';
import '../App.css';
import { logEvent } from "firebase/analytics";
import { analytics } from '../index.js';
import { getDatabase, ref, onValue } from "firebase/database";
import { useParams } from 'react-router-dom';
import './BlogPostPage.css'; // Import the CSS file

function BlogPostPage() {
  const { postId } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    const fetchBlogPost = () => {
      const db = getDatabase();
      const blogPostRef = ref(db, `blog_posts/${postId}`);
      onValue(blogPostRef, (snapshot) => {
        const data = snapshot.val();
        setBlogPost(data);
      });
    };

    fetchBlogPost();
  }, [postId]);

  logEvent(analytics, 'Blog post page visited');

  const renderParagraph = (paragraph) => {
    const parts = paragraph.split(/(<li>.*?<\/li>)/g);
    return parts.map((part, index) => {
      if (part.startsWith("<li>")) {
        const listItem = part.replace(/<\/?li>/g, '');
        return <li key={index} dangerouslySetInnerHTML={{ __html: listItem }} />;
      } else if (part.trim() !== "") {
        return <p key={index}>{part}</p>;
      }
      return null;
    });
  };

  if (!blogPost) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="container blog-post-container">
      <h1 className="title blog-post-title">{blogPost.title}</h1>
      <section className="section blog-post-content">
        {blogPost.sections.map((section, index) => (
          <div key={index} className="blog-post-section">
            <h2 className="blog-post-subtitle">{section.subtitle}</h2>
            <div className="blog-post-paragraph">{renderParagraph(section.paragraph)}</div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default BlogPostPage;
