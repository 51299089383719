import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="container mx-auto flex justify-center">
        <a 
          href="https://x.com/HrvOpen23623" 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-white hover:text-blue-400 transition-colors duration-300"
          aria-label="Follow us on Twitter"
        >
          <i className="fab fa-twitter text-2xl"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;