import React from 'react';
import './App.css';
import polarH10Image from './images/polar_h10.jpg';
import FormComponent from './SubscribeForm.js';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';
import './Wearables.css';

function WearablePage() {
  logEvent(analytics, 'Wearable page visited');
  
  return (
    <div className="container">
      <h1 className="wearable-title">Wearable Integration</h1>
      <p className="wearable-tagline">Connect Your Wearable with Open HRV</p>

      <div className="hero-wearable">
        <div className="hero-content">
          <h2 className="hero-title">New: Polar H10 Integration!</h2>
          <p className="hero-description">
            We're excited to announce our latest integration with the Polar H10 device!
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.j_ware.polarsensorlogger&hl=pl" 
             className="cta-button" 
             target="_blank" 
             rel="noopener noreferrer">
            Get Polar Sensor Logger App
          </a>
        </div>
        <div className="hero-image">
          <img src={polarH10Image} alt="Polar H10 Device" />
        </div>
      </div>

      <section className="feature-section">
        <h2>Why Choose Polar H10 with Open HRV?</h2>
        <ul className="feature-list">
          <li>High-precision heart rate monitoring</li>
          <li>Easy integration with our platform</li>
          <li>Real-time data syncing</li>
          <li>Advanced HRV analytics</li>
        </ul>
      </section>

      <section className="integration-steps">
        <h2>How to Integrate Your Polar H10</h2>
        <ol>
          <li>Download the Polar Sensor Logger app from the Google Play Store</li>
          <li>Connect your Polar H10 device to the app</li>
          <li>Select ECG and any other data that you want to record</li>
          <li>Start recording your data. While you choose Save Data it will automatically cache it on your device and ask you for send to cloud</li>
          <li>Download file with format: Polar_H10_XXXXXXXXXXX_ECG </li>
          <li>Upload your data to Open HRV. Set parameters Data type ECG and sample rate 130 Hz</li>
          <li>Use segmentation for better accuracy</li>
          <li>Start tracking your HRV data!</li>
        </ol>
      </section>

      {/* Original content can be placed here, perhaps in a collapsible section */}

      <div className="subscribe-container">
        <div className="content">
          <h2>Stay Updated on Our Latest Integrations!</h2>
          <FormComponent />
        </div>
      </div>
    </div>
  );
}

export default WearablePage;