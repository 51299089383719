import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase_config';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, listAll } from 'firebase/storage';

const Dashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [userFiles, setUserFiles] = useState([]);

  useEffect(() => {
    if (user) {
      fetchUserData();
      fetchUserFiles();
    }
  }, [user]);

  const fetchUserData = async () => {
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (userDoc.exists()) {
      setUserData(userDoc.data());
    }
  };

  const fetchUserFiles = async () => {
    const storage = getStorage();
    const listRef = ref(storage, `user-files/${user.uid}`);
    const res = await listAll(listRef);
    setUserFiles(res.items);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!user) return <div>Please sign in to view this page</div>;

  return (
    <div className='container' >
      <h1>Welcome, {user.displayName}</h1>
      {userData && (
        <div>
          <h2>User Information</h2>
          <p>Email: {userData.email}</p>
          {/* Add more user data fields as needed */}
        </div>
      )}
      <h2>Your Files</h2>
      <ul>
        {userFiles.map((file, index) => (
          <li key={index}>{file.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default Dashboard;