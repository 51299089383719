import React from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';
import db from './config/firebase_config.js';
import { useState } from 'react'
import { collection, addDoc } from "firebase/firestore";
import './ContactPage.css'
function ContactPage() {

  const successMessage = document.getElementById('success-message');
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const submitHandler = async (e) => {
    e.preventDefault();
    if(email && name && message) {
      console.log(message);
      try {
        const docRef = await addDoc(collection(db, "messages"), {
          email: email,
          name: name,
          message: message
        });
        console.log("Document written with ID: ", docRef.id);
        successMessage.textContent = `Thank you, ${name}, for your message!`;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }

  logEvent(analytics, 'Contact page visited');
  return(
    <div className="contact-container container">
    <h1>Contact</h1>
    <h4>Want to get some more info about the project or collaborate with us. We can provide you complex solutions based on HRV</h4>
    <br/>
    <h4>Send us message!</h4>
    <form className="contact-form" onSubmit={submitHandler}>
        <label for="name">Name:</label>
        <input type="text" value={name} id="name" name="name" onChange={e => setName(e.target.value)} required/>
        <label for="email">Email:</label>
        <input type="email" value={email} id="email" name="email" onChange={e => setEmail(e.target.value)} required/>
        <label for="message">Message:</label>
        <textarea className="message" value={message} name="message" rows="5" onChange={e => setMessage(e.target.value)}  required></textarea>
        <br/>
        <button type="submit">Send Message</button>
    </form>
    <p id="success-message"></p>
    <br/>
    <p>Or send email to: przemyslaw.piotr.marciniak@gmail.com</p>
    </div>
  );
}

export default ContactPage