import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import './DemoPage.css';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';
import HRVSingleRecordingForm from './HRVSingleRecordingForm';

function DemoPage() {
  logEvent(analytics, 'Demo page visited');

  return (
    <div className="demo-container container">
      <h1 className="title">Open HRV</h1>
      <h2 className="subtitle">Free online Heart Rate Variability Analyzer</h2>

      <div className="onboarding-container">
        <div className="onboarding-video">
          {/* Add a short video or animation explaining the steps */}
        </div>
        <div className="sample-dataset">
          <p>Try our app with a sample data:</p>
          <a
            href="https://drive.google.com/uc?export=download&id=1JvW9vTj2RQaRrvFm0BLVtJ0uSEU3c3rG"
            download
            className="sample-dataset-button subscribe-button"
          >
            Download Sample Dataset
          </a>
        </div>
      </div>

      <div className="integrate-container">
        <h3>Integrate with your wearable device</h3>
        <div className="wearable-devices">
          {/* Add visuals or icons for different wearable devices */}
        </div>
        <p>Unlock the power of AI-driven Heart Rate Variability analysis by seamlessly integrating your wearable device with Open HRV.</p>
        <Link to="/wearables-we-support" className="integrate-button subscribe-button">Learn More</Link>
      </div>

      <div className="demo-form-and-desc">
        <div className="demo-description">
          <h3>How it works:</h3>
          <ol>
            <li>Record data from your wearable device</li>
            <li>Extract data as a supported file format</li>
            <li>Upload the file using the form below</li>
            <li>Choose proper parameters</li>
            <li>Click "Calculate" to see your personalized HRV report</li>
          </ol>
          <p>Supported file formats: .csv and .edf (European Data Format), .acq (AcqKnowledge), .txt (text file), .json, .xml, .mat (Matlab)</p>
          <p>Please reach us if you interested in more</p>
        </div>

        <div className="demo-form">
          <HRVSingleRecordingForm />
        </div>
      </div>

      <div className="data-visualization">
        <h3>Sample HRV Analysis Results</h3>
        {/* Add visually appealing charts or graphs */}
      </div>

      <div className="faq-section">
        <h3>Frequently Asked Questions</h3>
        {/* Add a list of FAQs */}
      </div>

      <div className="navigation">
        <Link to="/science" className="nav-link">Science</Link>
        <Link to="/ketamine" className="nav-link">Ketamine</Link>
        <Link to="/about" className="nav-link">About Us</Link>
      </div>
    </div>
  );
}

export default DemoPage;