import React, { useState, useEffect } from 'react';
import '../App.css';
import { logEvent } from "firebase/analytics";
import { analytics } from '../index.js';
import { getDatabase, ref, onValue } from "firebase/database";
import { Link } from 'react-router-dom';
import './BlogPage.css'; // Import the CSS file

function BlogPage() {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = () => {
      const db = getDatabase();
      const blogPostsRef = ref(db, 'blog_posts');
      onValue(blogPostsRef, (snapshot) => {
        const data = snapshot.val();

        if (data) {
          const blogPostsData = Object.entries(data).map(([id, post]) => ({
            id,
            ...post,
          }));
          setBlogPosts(blogPostsData);
        }
      });
    };

    fetchBlogPosts();
  }, []);

  logEvent(analytics, 'Blog page visited');

  return (
    <div className="container blog-container">
      <h1>Open HRV Blog</h1>
      <p>Insights and Updates from the Open HRV Team</p>

      <section className="section blog-posts">
        {blogPosts.map((post) => (
          <div key={post.id} className="blog-post-block">
            <Link to={`/blog/${post.id}`} className="blog-post-link">
              <h2 className="blog-post-title">{post.title}</h2>
              <p className="blog-post-short">{post.short}</p>
            </Link>
          </div>
        ))}
      </section>
    </div>
  );
}

export default BlogPage;