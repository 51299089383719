import { useState, useEffect } from 'react'
import './App.css';
import styled from 'styled-components'
import { collection, addDoc, doc, setDoc, serverTimestamp } from "firebase/firestore";
import db from './config/firebase_config.js';
import { Alert } from '@mui/material';

import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DiscordIcon from '@mui/icons-material/Chat';

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: '1rem',
  marginBottom: '1rem',
  width: '50%',
}));

const Form = styled.form`
position: relative;
@media (max-width: 2160px){
  padding: 3rem;
  min-width: 50%;
  border-radius: 5px;
  box-shadow: 0 0 30px #333;
  background: rgba(255, 255, 255, 0.1);
  border: solid 1px rgba(255, 255, 255, 0.2);
  background-clip: padding-box;
  backdrop-filter: blur(10px);
}
@media (max-width: 768px) {
  padding: 3rem;
  min-width: 50%;
  border-radius: 0px;
  background: rgba(255, 255, 255, 0);
  border: none;
  box-shadow: 0 0 0;
  background-clip: none;
  backdrop-filter: blur(0px);
  display: block;
}
`;

const FormComponent = () => {
    const [email, setEmail] = useState('')
    const [popup, setPopup] = useState(false)
    const [error, setError] = useState('')
    const submitHandler = async (e) => {
      e.preventDefault();
      if(email) {
        console.log(email);
        try {
          // Custom document ID could be a hashed or transformed version of the email
          const documentId = `email-${new Date().getTime()}`; // Example custom ID
          const docRef = doc(db, "emails", documentId);
          await setDoc(docRef, {
            email: email, // corrected field name from 'eemail' to 'email'
            createdAt: serverTimestamp() // adds a server-side timestamp
          });
          console.log("Document written with ID: ", docRef.id);
          setEmail('')
          setPopup(true)
        } catch (e) {
          setError(e)
          console.error("Error adding document: ", e);
        }
      } else {
        console.log('Please enter an email')
      };
    }
  
    return (
        <Form elevation={3}>
          <Typography variant="h4" component="h2" gutterBottom>
            Let's stay in touch!
          </Typography>
          <form onSubmit={submitHandler} style={{ width: '100%' }}>
            <TextField
              fullWidth
              type="email"
              value={email}
              name="email"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              required
            />
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<MailOutlineIcon />}
            >
              Subscribe for newsletter and beta access
            </StyledButton>
          </form>
          {popup && <Alert severity="success">Thank you for subscribing!</Alert>}
          {error && <Alert severity="error">Error: {error}</Alert>}
          
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Join our community!
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              startIcon={<DiscordIcon />}
              component={Link}
              href="https://discord.gg/Q8EWFVaF"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join our Discord channel
            </Button>
          </Box>
        </Form>
    )
}

export default FormComponent;
