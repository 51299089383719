import React, { useState } from 'react';
import './App.css';
import './index.css';
import './OpenHRVFAQ.css';
// Define a functional component for a single FAQ item
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={toggleOpen}>
      <h3 className="faq-question">{question}</h3>
      {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
  );
};

// Define a functional component named OpenHRVFAQ
const OpenHRVFAQ = () => {
  // An array of FAQ objects, each containing a question and answer
  const faqs = [
    {
      question: "What about privacy related to the data I contribute?",
      answer: "Open HRV complies with GDPR and HIPAA, so all data involved in our system is carefully handled so that no one's privacy is in jeopardy in the case of a data leak (which is highly unlikely to start with).",
    },
    {
      question: "Who owns the data I contribute?",
      answer: "There is a joint ownership between you and the Open HRV company. If you are not happy sharing your data with us, you can always ask for it to be removed from our database.",
    },
    {
      question: "What algorithms do you use to process my data?",
      answer: "We utilize various algorithms for developing HRV features and data models based on them. However, we are not at liberty to discuss them here. Suffice it to say that they are similar to the algorithms commonly used in this domain.",
    },
    {
      question: "What platform do you use for processing my data?",
      answer: "We use the Julia and Python programming language for the most part, as well as Javascript.",
    },
    {
      question: "Who can contribute to this project and how?",
      answer: "Anyone with HRV data can contribute their data to this project. If you have domain knowledge and some technical expertise, you could also contribute in more hands-on ways. Feel free to contact us, if that's the case.",
    },
    {
      question: "Where are you located?",
      answer: "We are located in Europe, mainly Poland. However, we are open to working with people all over the world.",
    },
    {
        question: "What is the purpose of Open HRV?",
        answer: "Open HRV is a project that aims to democratize HRV data analysis. We want to make it accessible to everyone, regardless of their technical background. We want to help also researchers with well-documented data and tools. We want to make it easy for developers to integrate HRV analysis into their applications. We want to help hardware producers to create better devices. We want to help doctors to diagnose patients. We want to help patients to understand their health. We want to help athletes to improve their performance. We want to help everyone to live a healthier life. We want to help you.",
    }
  ];

  return (
    <div className="faq-container">
      <h2>FAQ for Open HRV</h2>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default OpenHRVFAQ;
