import React from 'react';
import './App.css';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';
import heartRateImage from './images/heart-rate.png';
import wearableImage from './images/HRV-wearable_devices.png';
import valuesImage from './images/values.png';
import './AboutPage.css';
import FormComponent from './SubscribeForm.js';
function AboutPage() {
  logEvent(analytics, 'About page visited');
  return (
    <div className="container">
        <h1 className="title">Open HRV</h1>
        <p className="tagline">Unlocking the Power of Heart Rate Variability</p>

      <section className="section">
        <div className="content">
          <h2>What is HRV?</h2>
          <p className="description">
            Heart Rate Variability (HRV) enables non-invasive monitoring of the activity of the autonomic nervous system. The number of studies investigating HRV is constantly increasing due to the great value of this technology. Generally, high HRV is related to healthy conditions, whereas low HRV is associated with the presence of pathology in our body.
          </p>
          <p className="description">
            What is important, it can be affected by our lifestyle and environmental factors. Taking it into consideration, HRV might not only enable the diagnosis of pathology but also help us monitor the recovery.
          </p>
          <p className="description">
            The research shows that HRV reflects not only heart diseases. The variation of time between two heartbeats may also be useful in monitoring diabetes, depression, cancer, and many other pathological conditions.
          </p>
        </div>
        <div className="image-container">
          <img src={heartRateImage} alt="Heart Rate" className="image" />
        </div>
      </section>

      <section className="section">
        <div className="content">
          <h2>Our Solution</h2>
          <p className="description">
            We are developing a seamless protocol which integrates with your wearable (check wearables which we support <a href="/wearables-we-support">here</a>). You can always get history of your data, add some context notes to it or if you want you can share your data with our research group or other research group! We are build crowdsourcing community of users building new paradigm of research and therapy. You can be a part of it!
          </p>
        </div>
        <div className="image-container">
          <img src={wearableImage} alt="Wearable" className="image" />
        </div>
      </section>

      <section className="section values-section">
        <div className="content">
          <h2>Our Values</h2>
          <ul className="values-list">
            <li>
              <img src={valuesImage} alt="Value" className="value-icon" />
              <div className="value-details">
                <span className="value-text">Transparency</span>
                <p className="value-description">We believe that the potential of biomarkers should be accessible for everyone. There is still little knowledge in the society and within the medical doctors about how it can be used.</p>
              </div>
            </li>
            <li>
              <img src={valuesImage} alt="Value" className="value-icon" />
              <div className="value-details">
                <span className="value-text">Helpfulness</span>
                <p className="value-description">We as a company shouldn't operate if we are not able to help in the treatment of any conditions. We believe in our potential but also care about the credibility and effectiveness of our product.</p>
              </div>
            </li>
            <li>
              <img src={valuesImage} alt="Value" className="value-icon" />
              <div className="value-details">
                <span className="value-text">Empathy</span>
                <p className="value-description">We are dealing sometimes with very sensitive data and working on serious medical conditions. We need to respect that and understand the value of what we are doing for others.</p>
              </div>
            </li>
            <li>
              <img src={valuesImage} alt="Value" className="value-icon" />
              <div className="value-details">
                <span className="value-text">Inclusivity</span>
                <p className="value-description">We are building an interdisciplinary team working together. We should respect everyone's background and ideas, and be curious about learning more from the perspective of others.</p>
              </div>
            </li>
            <li>
              <img src={valuesImage} alt="Value" className="value-icon" />
              <div className="value-details">
                <span className="value-text">Curiosity</span>
                <p className="value-description">While working in such an environment, it is very important to gain knowledge on a variety of topics and be responsible for your growth.</p>
              </div>
            </li>
            <li>
              <img src={valuesImage} alt="Value" className="value-icon" />
              <div className="value-details">
                <span className="value-text">Freedom</span>
                <p className="value-description">We do not want to have any constraints when it comes to place or time of working.</p>
              </div>
            </li>
            <li>
              <img src={valuesImage} alt="Value" className="value-icon" />
              <div className="value-details">
                <span className="value-text">Creativity</span>
                <p className="value-description">It is important for us to generate new ideas and share them. Our operations should embrace that.</p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <div className="subscribe-container">
      <div className="content">
          <h2>Want to know more! Subscribe!</h2>
          <p className="description">
          <FormComponent>
        </FormComponent>
        </p>
        </div>
        </div>
    </div>
  );
}

export default AboutPage;