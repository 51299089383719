import './TeamPage.css';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';
import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL } from "firebase/storage";
import {storage} from './index.js';

const TeamMemberWithFirebaseImage = (props) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        console.log("props.image: ", props.image);
        console.log("storage: ", storage);
        const imageRef = ref(storage, props.image);
        getDownloadURL(imageRef)
            .then((url) => {
                setImageUrl(url);
            })
            .catch((error) => {
                console.error("Error downloading image: ", error);
            });
    }, [props.image]);

    return (
        <TeamMember {...props} image={imageUrl} />
    );
}

const TeamMember = (props) => {
  return(
    <div className="team-member">
      {props.image ? 
          <img className="team-member-image" src={props.image} alt={props.name} /> :
          <div className="image-placeholder">Loading...</div>
      }
      <p className="team-member-name">{props.name}</p>
      <p className="team-member-role">{props.role}</p>
      <p className="team-member-description">{props.description}</p>
      <a href={props.linkedin} target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i> LinkedIn
      </a>
    </div>
  )
}


function TeamPage() {
  logEvent(analytics, 'Team page visited');

  return(
    <div className="container team-container">
      <h1 className="title">Team</h1>
      <div className="team-members">
      <TeamMemberWithFirebaseImage
                image='founders_foto/przemek.jpeg'
                name="Przemysław Marciniak"
                role="CEO"
                description="Przemysław Marciniak is originating from Poland. He has academic background in the cognitive science and electronics engineer. He was part of the neuropsychological project on the social cognition where he combined his skill to work with HRV data. From that project OPEN HRV originated. He also pursed carerr as a software engineer in big tech companies working with backend, OS and mobile. He was part of EIT Health programme and Polish Development Found programme"
                linkedin="https://www.linkedin.com/in/przemyslaw-marciniak/"
        />
        <TeamMemberWithFirebaseImage
          image="founders_foto/bartek.jpeg"
          name="Bartosz Sobocki"
          role="CMO"
          description="Bartosz Sobocki is also originated from Poland. He finnished PhD in medicine and is conducting extensive research on cancer therapy and biomarkers. He was part of EIT Health programme"
          linkedin="https://www.linkedin.com/in/bartosz-kamil-sobocki/"
        ></TeamMemberWithFirebaseImage>
        <TeamMemberWithFirebaseImage
          image="founders_foto/zack.jpeg"
          name="Zacharias Voulgaris"
          role="CDSO"
          description="Zacharias Voulgaris was born in Athens, Greece. He studied Production Engineering and Management at the Technical University of Crete, shifted to Computer Science through a Masters in Information Systems & Technology, and then to Data Science through a PhD on Machine Learning. He has worked at Georgia Tech as a Research Fellow, at an e-marketing startup in Cyprus as an SEO manager, and as a Data Scientist in both Elavon (GA) and G2 Web Services (WA). He also was a Program Manager at Microsoft, on a data analytics pipeline for Bing. Zacharias has authored seven books on Data Science, mentors aspiring data scientists as well as other professionals interested in incorporating data science in their businesses, and maintains a Data Science & AI blog."
          linkedin="https://www.linkedin.com/in/zvoulgaris/"
        ></TeamMemberWithFirebaseImage>
      </div>
    </div>
  )
}

export default TeamPage;