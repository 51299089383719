import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/app';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzDBVjT7Rhv4eS91Dci4ngSQrN2SHgVlg",
  authDomain: "openhrv.firebaseapp.com",
  projectId: "openhrv",
  storageBucket: "openhrv.appspot.com",
  messagingSenderId: "660633507663",
  appId: "1:660633507663:web:ddb8070ea823f635c6173a",
  measurementId: "G-DTM32SJLEC",
  databaseURL: "https://openhrv-default-rtdb.europe-west1.firebasedatabase.app",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export const auth = getAuth(firebaseApp);
export const googleProvider = new GoogleAuthProvider();

export default db;

//export { storage };